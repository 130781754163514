import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Select,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import SupportInfoModal from "./SupportInfoModal";
import { UpdateApplicantSupportInfo } from "./ProfilePageRepository";
import { updateSupportInfo as apiUpdateSupportInfo } from "./ProfilePageRepository";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export type AddressInfo = {
  streetName: string;
  zipCode: string;
  city: string;
};

export type WorkHoursInfo = {
  canWorkDaytime: boolean;
  canWorkEvening: boolean;
  canWorkNighttime: boolean;
};

export type SupportInfoFieldValueMap = {
  [SupportInfoFieldType.Address]: AddressInfo;
  [SupportInfoFieldType.PhoneNumber]: string;
  [SupportInfoFieldType.FirstPreferredJob]: string;
  [SupportInfoFieldType.SecondPreferredJob]: string;
  [SupportInfoFieldType.ThirdPreferredJob]: string;
  [SupportInfoFieldType.DesiredJobInFiveYears]: string;
  [SupportInfoFieldType.HasWorkLimitations]: boolean;
  [SupportInfoFieldType.CanTakeWorkImmediately]: boolean;
  [SupportInfoFieldType.WorkHours]: WorkHoursInfo;
  [SupportInfoFieldType.WorkTime]: number;
  [SupportInfoFieldType.UnemploymentOrSickLeaveDate]: string;
  [SupportInfoFieldType.SfiLevel]: string;
  [SupportInfoFieldType.EducationLevel]: string;
  [SupportInfoFieldType.Description]: string;
  [SupportInfoFieldType.JobSearchLocation]: string[];
  [SupportInfoFieldType.Gender]: string;
};

export enum SupportInfoFieldType {
  Address = "address",
  PhoneNumber = "phoneNumber",
  FirstPreferredJob = "firstPreferredJob",
  SecondPreferredJob = "secondPreferredJob",
  ThirdPreferredJob = "thirdPreferredJob",
  DesiredJobInFiveYears = "desiredJobInFiveYears",
  HasWorkLimitations = "hasWorkLimitations",
  CanTakeWorkImmediately = "canTakeWorkImmediately",
  WorkHours = "workHours",
  WorkTime = "workTime",
  UnemploymentOrSickLeaveDate = "unemploymentOrSickLeaveDate",
  SfiLevel = "sfiLevel",
  EducationLevel = "educationLevel",
  Description = "description",
  JobSearchLocation = "jobSearchLocation",
  Gender = "gender",
}

interface SupportInfoFieldProps<T extends SupportInfoFieldType> {
  type: T;
  info: SupportInfoFieldValueMap[T];
  leadingSlot?: JSX.Element;
  getUpdatedSupportInfo: (value: SupportInfoFieldValueMap[T]) => UpdateApplicantSupportInfo | undefined;
}

const SupportInfoField = <T extends SupportInfoFieldType>({
  type,
  info,
  leadingSlot,
  getUpdatedSupportInfo,
}: SupportInfoFieldProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsModalOpen(true);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  const displayInfo = (): string => {
    switch (type) {
      case SupportInfoFieldType.Address: {
        const addressInfo = info as AddressInfo;
        return `${addressInfo.streetName}, ${addressInfo.zipCode}, ${addressInfo.city}`;
      }
      case SupportInfoFieldType.WorkTime: {
        const value = info as number;
        return value === 100 ? t("profilePage.supportInfo.fullTimeLabel") : `${value}%`;
      }
      case SupportInfoFieldType.Gender: {
        const gender = info as keyof typeof GenderTranslations;
        return t(GenderTranslations[gender]);
      }
      case SupportInfoFieldType.JobSearchLocation: {
        const locations = info as string[];
        return locations.join(", ");
      }
      default: {
        return info as string;
      }
    }
  };

  const typeLabel = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
        return t("profilePage.supportInfo.jobSearchLocationLabel");
      case SupportInfoFieldType.Gender:
        return t("profilePage.supportInfo.genderLabel");
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addressLabel");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.phoneNumberLabel");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.firstPreferredJobLabel");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.secondPreferredJobLabel");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.thirdPreferredJobLabel");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.desiredJobInFiveYearsLabel");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.workTimeLabel");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel");
      default:
        return null;
    }
  };

  const handleSave = async (value: SupportInfoFieldValueMap[T]) => {
    const typeText = typeLabel();
    try {
      const updatedSupportInfo = getUpdatedSupportInfo(value);
      if (!updatedSupportInfo) {
        return;
      }
      const apiUpdatedSupportInfo = await apiUpdateSupportInfo(updatedSupportInfo);
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      if (typeText) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.saveSuccess", {
              typeLabel: typeText,
            }),
            severity: "success",
          }),
        );
      }
    } catch (error) {
      if (typeText) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.saveError", {
              typeLabel: typeText,
            }),
            severity: "error",
          }),
        );
      }
    }
  };

  const renderField = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
      case SupportInfoFieldType.Gender:
      case SupportInfoFieldType.Address:
      case SupportInfoFieldType.PhoneNumber:
      case SupportInfoFieldType.FirstPreferredJob:
      case SupportInfoFieldType.SecondPreferredJob:
      case SupportInfoFieldType.ThirdPreferredJob:
      case SupportInfoFieldType.DesiredJobInFiveYears:
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
      case SupportInfoFieldType.WorkTime:
        return renderTextInput(info as string, type, leadingSlot, displayInfo, handleDialogOpen, t);

      case SupportInfoFieldType.HasWorkLimitations:
      case SupportInfoFieldType.CanTakeWorkImmediately:
        return (
          <SupportInfoSwitchField
            initialValue={info as boolean}
            leadingSlot={leadingSlot}
            handleSave={(value: boolean) => handleSave(value as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );

      case SupportInfoFieldType.WorkHours: {
        const workHoursInfo = info as WorkHoursInfo;
        return (
          <WorkHoursField
            dayWorkInitialValue={workHoursInfo.canWorkDaytime}
            eveningWorkInitialValue={workHoursInfo.canWorkEvening}
            nightWorkInitialValue={workHoursInfo.canWorkNighttime}
            leadingSlot={leadingSlot}
            handleSave={(value: WorkHoursInfo) => handleSave(value as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );
      }

      case SupportInfoFieldType.SfiLevel:
        return (
          <SfiLevelField
            info={info as string}
            leadingSlot={leadingSlot}
            handleSave={(value: string) => handleSave(value as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );

      case SupportInfoFieldType.EducationLevel:
        return (
          <EducationLevelField
            info={info as string}
            leadingSlot={leadingSlot}
            handleSave={(value: string) => handleSave(value as SupportInfoFieldValueMap[T])}
            t={t}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {renderField()}
      <SupportInfoModal
        type={type}
        open={isModalOpen}
        onClose={handleDialogClose}
        getUpdatedSupportInfo={getUpdatedSupportInfo}
        initialValue={info}
      />
    </Box>
  );
};

const renderTextInput = (
  info: string | AddressInfo | string[],
  type: SupportInfoFieldType,
  leadingSlot: JSX.Element | undefined,
  displayInfo: () => string,
  handleDialogOpen: () => void,
  t: TFunction,
) => {
  const isInfoIncomplete = (): boolean => {
    switch (type) {
      case SupportInfoFieldType.Address: {
        const addressInfo = info as AddressInfo;
        return !addressInfo || !addressInfo.streetName || !addressInfo.zipCode || !addressInfo.city;
      }
      default:
        return !info;
    }
  };

  const label = () => {
    switch (type) {
      case SupportInfoFieldType.JobSearchLocation:
        return t("profilePage.supportInfo.addJobSearchLocation");
      case SupportInfoFieldType.Gender:
        return t("profilePage.supportInfo.addGender");
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addAddress");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.addPhoneNumber");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.addFirstPreferredJob");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.addSecondPreferredJob");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.addThirdPreferredJob");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.addDesiredJobInFiveYears");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.addWorkPercentage");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.addUnemploymentDate");
      default:
        return "";
    }
  };

  return (
    <Box sx={{ textAlign: "left" }} onClick={handleDialogOpen}>
      {isInfoIncomplete() ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover span": {
              textDecoration: "underline",
            },
          }}
        >
          <IconButton sx={{ mr: 1, color: "secondary.main" }}>
            <AddCircleOutline sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            <Box component="span" sx={{ display: "block" }}>
              {label()}
            </Box>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover span": {
              textDecoration: "underline",
            },
            "&:hover .edit-icon": {
              visibility: "visible",
            },
          }}
        >
          {leadingSlot ? leadingSlot : <></>}
          <Typography variant="subtitle1" sx={{}}>
            <Box component="span" sx={{ display: "block" }}>
              {displayInfo()}
            </Box>
          </Typography>
          <IconButton onClick={handleDialogOpen} className="edit-icon" sx={{ mx: 1, visibility: "hidden" }}>
            <Edit />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

interface SupportInfoSwitchFieldProps {
  initialValue: boolean;
  leadingSlot?: JSX.Element;
  handleSave: (value: boolean) => void;
  t: TFunction;
}

const SupportInfoSwitchField: React.FC<SupportInfoSwitchFieldProps> = ({ initialValue, leadingSlot, handleSave }) => {
  const [value, setValue] = useState<boolean>(initialValue);
  return (
    <Box display={"flex"} flexDirection={"row"}>
      {leadingSlot ? leadingSlot : <></>}
      <Checkbox
        checked={value}
        onChange={(event) => {
          setValue(event.target.checked);
          handleSave(event.target.checked);
        }}
      />
    </Box>
  );
};

interface WorkHoursFieldProps {
  dayWorkInitialValue: boolean;
  eveningWorkInitialValue: boolean;
  nightWorkInitialValue: boolean;
  leadingSlot?: JSX.Element;
  handleSave: (value: WorkHoursInfo) => void;
  t: TFunction;
}

const WorkHoursField: React.FC<WorkHoursFieldProps> = ({
  dayWorkInitialValue,
  eveningWorkInitialValue,
  nightWorkInitialValue,
  leadingSlot,
  handleSave,
  t,
}) => {
  const [value, setValue] = useState({
    canWorkDaytime: dayWorkInitialValue,
    canWorkEvening: eveningWorkInitialValue,
    canWorkNighttime: nightWorkInitialValue,
  });
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {leadingSlot ? leadingSlot : <></>}
        <Typography variant="subtitle1">{t("profilePage.supportInfo.canWorkDaytime")}</Typography>
        <Checkbox
          checked={value.canWorkDaytime}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkDaytime: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />

        <Typography variant="subtitle1" sx={{ ml: 5 }}>
          {t("profilePage.supportInfo.canWorkEvening")}
        </Typography>
        <Checkbox
          checked={value.canWorkEvening}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkEvening: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />

        <Typography variant="subtitle1" sx={{ ml: 5 }}>
          {t("profilePage.supportInfo.canWorkNighttime")}
        </Typography>
        <Checkbox
          checked={value.canWorkNighttime}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkNighttime: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />
      </Box>
    </Box>
  );
};

interface EducationLevelFieldProps {
  info: string;
  leadingSlot: JSX.Element | undefined;
  handleSave: (value: string) => void;
  t: TFunction;
}

enum EducationLevels {
  NO_EDUCATION = "noEducation",
  LESS_THAN_SEVEN = "lessThanSevenYears",
  SEVEN_TO_NINE = "sevenToNineYears",
  HIGH_SCHOOL = "highSchool",
  HIGHER_EDU = "higherEducation",
}

const EducationLevelField: React.FC<EducationLevelFieldProps> = ({ info, leadingSlot, handleSave, t }) => {
  const [value, setValue] = useState(info);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {leadingSlot && leadingSlot}
      <Select
        value={value}
        onChange={(event: SelectChangeEvent<string>) => {
          const selectedValue = event.target.value;
          setValue(selectedValue);
          handleSave(selectedValue);
        }}
        sx={{ minWidth: "200px", ml: 4 }}
      >
        {Object.entries(EducationLevels).map(([key, displayText]) => (
          <MenuItem key={key} value={key}>
            {t(`profilePage.supportInfo.${displayText}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

interface SfiLevelFieldProps {
  info: string;
  leadingSlot?: React.ReactNode;
  handleSave: (value: string) => void;
  t: TFunction;
}

enum SfiLevels {
  MOTHER_TONGUE = "MOTHER_TONGUE",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  SAS_G = "SAS G",
}

const GenderTranslations = {
  MAN: "profilePage.supportInfo.genderMan",
  WOMAN: "profilePage.supportInfo.genderWoman",
  OTHER: "profilePage.supportInfo.genderOther",
};

const SfiLevelField: React.FC<SfiLevelFieldProps> = ({ info, leadingSlot, handleSave, t }) => {
  const [isMotherTongue, setIsMotherTongue] = useState(info === SfiLevels.MOTHER_TONGUE);
  const [value, setValue] = useState(isMotherTongue ? SfiLevels.MOTHER_TONGUE : info);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsMotherTongue(checked);
    if (checked) {
      handleSave(SfiLevels.MOTHER_TONGUE);
    } else if (value === SfiLevels.MOTHER_TONGUE) {
      setValue("");
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    handleSave(selectedValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {leadingSlot && leadingSlot}
      <FormControlLabel
        control={<Checkbox checked={isMotherTongue} onChange={handleCheckboxChange} name="motherTongue" />}
        label={t("profilePage.supportInfo.motherTongueLabel")}
        labelPlacement="start"
        sx={{ ml: 0 }}
      />
      {!isMotherTongue && (
        <Select value={value} onChange={handleSelectChange} sx={{ minWidth: "200px", ml: 4 }}>
          {Object.entries(SfiLevels)
            .filter(([key]) => key !== "MOTHER_TONGUE")
            .map(([key, displayText]) => (
              <MenuItem key={key} value={key}>
                {displayText}
              </MenuItem>
            ))}
        </Select>
      )}
    </Box>
  );
};

export default SupportInfoField;
