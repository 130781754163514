import { Button, Card, CardActionArea, CardContent, List, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllApplications } from "./ApplicationsListRepositoryt";
import { Application } from "../../types/Application";
import MaijaContainer from "../../components/MaijaContainer";
import { addApplication, removeApplication, setApplications } from "../../reducers/applicationsListPageSlice";
import { useNavigate } from "react-router-dom";
import { APPLICATION_ROUTE, navigateToApplicationDetails } from "../../Routes";
import { Box } from "@mui/system";
import { Inbox } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../hooks";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import {
  clearApplication,
  setApplicationDetailsFromApplication,
  updateApplication,
} from "../../reducers/applicantDetilsPageSlice";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteApplication, markApplicationAsApplied } from "../application/ApplicationRepository";
import { useTranslation } from "react-i18next";

const ApplicationsListPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const applications = useAppSelector((state) => state.applicationsListPage.applications);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (applications.length === 0) {
      setIsLoading(true);
    }
    getAllApplications()
      .then((applications) => {
        dispatch(setApplications(applications));
        setIsLoading(false);
      })
      .catch(() => {
        setError(t("applicationsList.errorFetching"));
        setIsLoading(false);
      });
  }, [applications.length, dispatch, t]);

  return (
    <MaijaContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ ml: "10%", mr: "10%", mt: 7 }}>
        {isLoading ? (
          <MaijaLoadingPage isFullscreen={true} />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : applications.length > 0 ? (
          <ApplicationsList applications={applications} />
        ) : (
          <ApplicationsEmptyState />
        )}
      </Box>
    </MaijaContainer>
  );
};

interface ApplicationsListProps {
  applications: Application[];
}

const ApplicationsList: React.FC<ApplicationsListProps> = ({ applications }) => {
  const { t } = useTranslation();
  const notAppliedApplications = applications
    .filter((app) => !app.hasApplied)
    .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
  const appliedApplications = applications
    .filter((app) => app.hasApplied)
    .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

  return (
    <>
      {notAppliedApplications.length > 0 && (
        <>
          <Typography variant="h4" component="h1" sx={{ mt: 5 }}>
            {t("applicationsList.titleWaiting")}
          </Typography>

          <List>
            {notAppliedApplications.map((application) => (
              <ApplicationCard key={application.id} application={application} />
            ))}
          </List>
        </>
      )}
      {appliedApplications.length > 0 && (
        <>
          <Typography variant="h4" component="h1" sx={{ mt: 5 }}>
            {t("applicationsList.titleSubmitted")}
          </Typography>
          <List>
            {appliedApplications.map((application) => (
              <ApplicationCard key={application.id} application={application} />
            ))}
          </List>
        </>
      )}
    </>
  );
};

const ApplicationCard = ({ application }: { application: Application }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [applyToApplicationOpen, setApplyToApplicationOpen] = useState(false);
  const [removeApplicationOpen, setRemoveApplicationOpen] = useState(false);

  const handleNavigateToDetails = (application: Application) => {
    dispatch(setApplicationDetailsFromApplication({ application: application, openedFromApplicationsList: true }));
    navigateToApplicationDetails(navigate, application.id);
  };

  const handleApply = () => {
    setApplyToApplicationOpen(true);
  };

  return (
    <>
      <Card sx={{ my: 2, borderRadius: 5 }}>
        <CardActionArea onClick={() => handleNavigateToDetails(application)}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box>
                <Typography variant="h5" component="h2">
                  {application.workplace}
                </Typography>
                <Typography color="textSecondary">{application.role}</Typography>
              </Box>
              <Box onClick={(e) => e.stopPropagation()}>
                {!application.hasApplied && (
                  <Button
                    variant="contained"
                    color="primary"
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleApply();
                    }}
                  >
                    {t("applicationsList.applyButton")}
                  </Button>
                )}

                {/* Applicants should probably not be able to delete
                <Button
                  variant="outlined"
                  color="error"
                  onMouseDown={(event) => event.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                  sx={{ ml: 2 }}
                >
                  Delete
                </Button> */}
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>

      <ConfirmActionDialog
        open={applyToApplicationOpen}
        title={t("applicationsList.confirmApplyTitle", { workplace: application.workplace })}
        description={t("applicationsList.confirmApplyDescription", {
          role: application.role,
          workplace: application.workplace,
        })}
        actionColor="primary"
        onClose={() => setApplyToApplicationOpen(false)}
        onAction={() => {
          markApplicationAsApplied(application.id, true)
            .then((response) => {
              dispatch(updateApplication(response));
              dispatch(removeApplication(response.id));
              dispatch(addApplication(response));
              dispatch(
                showSnackbar({
                  message: t("applicationsList.markedAsApplied"),
                  severity: "success",
                }),
              );
            })
            .catch((error) => {
              console.error(error);
              dispatch(
                showSnackbar({
                  message: t("applicationsList.errorUpdating"),
                  severity: "error",
                }),
              );
            });
        }}
      />

      <ConfirmActionDialog
        open={removeApplicationOpen}
        title={t("applicationsList.confirmDeleteTitle", { workplace: application.workplace })}
        description={t("applicationsList.confirmDeleteDescription", {
          role: application.role,
          workplace: application.workplace,
        })}
        actionColor="error"
        onClose={() => setRemoveApplicationOpen(false)}
        onAction={() => {
          deleteApplication(application.id)
            .then(() => {
              dispatch(clearApplication(application.id));
              dispatch(removeApplication(application.id));
              dispatch(
                showSnackbar({
                  message: t("applicationsList.removedApplication"),
                  severity: "success",
                }),
              );
            })
            .catch((error) => {
              console.error(error);
              dispatch(
                showSnackbar({
                  message: t("applicationsList.errorRemoving"),
                  severity: "error",
                }),
              );
            });
        }}
      />
    </>
  );
};

export const ApplicationsEmptyState = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleCreateApplication = () => {
    navigate(APPLICATION_ROUTE.path);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Inbox style={{ fontSize: 150 }} sx={{ mb: 5 }} />
          <Typography variant="h4" gutterBottom>
            {t("applicationsList.emptyStateTitle")}
          </Typography>

          <Button variant="contained" color="primary" onClick={handleCreateApplication} sx={{ m: 5 }}>
            {t("applicationsList.emptyStateButton")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ApplicationsListPage;
