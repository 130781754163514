import React from "react";
import { red, green, yellow } from "@mui/material/colors";
import { Box, Typography, Tooltip } from "@mui/material";
import { WorkExperience } from "../types/WorkExperience";
import { Education } from "../types/Education";
import { formatDateToString } from "../utils/DateUtils";
import { t, TFunction } from "i18next";

// Helper function to calculate the total duration and return boxes
const generateTimelineBoxes = (
  workExperiences: WorkExperience[],
  educations: Education[],
  t: TFunction,
): { timeline: JSX.Element[]; labels: JSX.Element[] } => {
  const timeline: JSX.Element[] = [];
  const labels: JSX.Element[] = [];
  let currentTime = new Date(workExperiences[0]?.fromDate || educations[0]?.fromDate);

  const getEarliestYear = () => {
    const workYears = workExperiences.map((exp) => new Date(exp.fromDate).getFullYear());
    const educationYears = educations.map((edu) => new Date(edu.fromDate).getFullYear());

    // Combine all years into one array
    const allYears = [...workYears, ...educationYears];

    // Get the earliest year
    return Math.min(...allYears);
  };

  // Helper to calculate the duration in days between two dates
  const calculateDuration = (start: string, end: string | null) => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : new Date();
    const duration = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    return duration;
  };

  // Process work experiences with durations
  const workDuration = workExperiences.map((work) => ({
    ...work,
    duration: calculateDuration(work.fromDate, work.toDate),
    type: "work" as const,
  }));

  // Process educations with durations
  const educationDuration = educations.map((education) => ({
    ...education,
    duration: calculateDuration(education.fromDate, education.toDate),
    type: "education" as const,
  }));

  // Combine and sort experiences by start date
  const combinedExperiences = [...workDuration, ...educationDuration].sort(
    (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
  );

  const experiences = combinedExperiences.map((experience) => ({
    type: experience.type,
    startDate: experience.fromDate,
    endDate: experience.toDate,
  }));

  let mostRecentExperience = new Date(getEarliestYear(), 0, 1);
  // Generate boxes based on the combined experiences
  combinedExperiences.forEach((experience) => {
    const firstYearDate = new Date(getEarliestYear(), 0, 1);
    const { duration, type, fromDate, toDate } = experience;
    const startDate = new Date(fromDate);
    const stopDate = new Date(toDate || new Date());
    const todaysDate = new Date();
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const totalDays = (todaysDate.getTime() - firstYearDate.getTime()) / millisecondsInDay;

    // Check if this is the first or last experience
    const isFirstEntry = combinedExperiences.indexOf(experience) === 0;
    const isLastEntry = combinedExperiences.indexOf(experience) === combinedExperiences.length - 1;

    let timeGap = 0;
    let left = 0;
    let overlaps = 0;
    let startDateUnemployment = mostRecentExperience.toISOString();
    let stopDateUnemployment = startDate.toISOString();

    for (let i = 0; i < combinedExperiences.indexOf(experience); i++) {
      if (experiences[i].endDate !== null && experiences[i].type === experience.type) {
        const prevEndDate = new Date(experiences[i].endDate || experiences[i].startDate);
        if (prevEndDate > startDate) {
          overlaps += 1;
        }
      }
    }

    if (isFirstEntry) {
      mostRecentExperience = stopDate;
      startDateUnemployment = mostRecentExperience.toISOString();
    }

    if (startDateUnemployment !== null && stopDateUnemployment !== null) {
      timeGap = calculateDuration(startDateUnemployment, stopDateUnemployment);
      left =
        (100 * (new Date(startDateUnemployment).getTime() - firstYearDate.getTime())) / (millisecondsInDay * totalDays);
    }

    if (mostRecentExperience < stopDate) {
      mostRecentExperience = stopDate;
    }

    if (timeGap > 30) {
      timeline.push(
        <Tooltip
          key={`gap-${currentTime}-${timeGap}`}
          title={
            <React.Fragment>
              <Typography variant="body2" color="inherit">
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
              <Typography variant="body2" color="inherit">
                {formatDateToString(startDateUnemployment)} - {formatDateToString(stopDateUnemployment)}
              </Typography>
            </React.Fragment>
          }
        >
          <Box
            bgcolor={red[500]}
            width={`${(100 * timeGap) / totalDays}%`}
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={0}
            mt={"80px"}
            sx={{
              p: 1,
              borderRadius: 1,
              transition: "background-color 0.3s ease",
              position: "absolute",
              left: `${left}%`,
              "&:hover": {
                backgroundColor: red[700],
              },
            }}
          >
            {timeGap > 100 && (
              <Typography variant="caption" color="inherit" noWrap sx={{ maxWidth: "100%" }}>
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
            )}
          </Box>
        </Tooltip>,
      );
    }

    if (isLastEntry && experience.toDate !== null) {
      startDateUnemployment = mostRecentExperience.toISOString();
      stopDateUnemployment = todaysDate.toString();
    }

    if (startDateUnemployment !== null && stopDateUnemployment !== null) {
      timeGap = calculateDuration(startDateUnemployment, stopDateUnemployment);
      left =
        (100 * (new Date(startDateUnemployment).getTime() - firstYearDate.getTime())) / (millisecondsInDay * totalDays);
    }

    if (timeGap > 30) {
      timeline.push(
        <Tooltip
          key={`gap-${currentTime}-${timeGap}`}
          title={
            <React.Fragment>
              <Typography variant="body2" color="inherit">
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
              <Typography variant="body2" color="inherit">
                {formatDateToString(startDateUnemployment)} - {formatDateToString(stopDateUnemployment)}
              </Typography>
            </React.Fragment>
          }
        >
          <Box
            bgcolor={red[500]}
            width={`${(100 * timeGap) / totalDays}%`}
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={0}
            mt={"80px"}
            sx={{
              p: 1,
              borderRadius: 1,
              transition: "background-color 0.3s ease",
              position: "absolute",
              left: `${left}%`,
              "&:hover": {
                backgroundColor: red[700],
              },
            }}
          >
            {timeGap > 100 && (
              <Typography variant="caption" color="inherit" noWrap sx={{ maxWidth: "100%" }}>
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
            )}
          </Box>
        </Tooltip>,
      );
    }

    // Add the work or education box with tooltip
    timeline.push(
      <Tooltip
        key={experience.id}
        title={
          <React.Fragment>
            <Typography variant="body2" color="inherit">
              {type === "work" ? experience.workplace : experience.provider}
            </Typography>
            <Typography variant="body2" color="inherit">
              {type === "work" ? experience.position : experience.subject}
            </Typography>
            <Typography variant="body2" color="inherit">
              {type === "work"
                ? t("profilePage.experienceTimeline.work")
                : t("profilePage.experienceTimeline.education")}
            </Typography>
            <Typography variant="body2" color="inherit">
              {formatDateToString(experience.fromDate)} -{" "}
              {experience.toDate ? formatDateToString(experience.toDate) : t("profilePage.education.present")}
            </Typography>
          </React.Fragment>
        }
      >
        <Box
          bgcolor={
            type === "work"
              ? green[(500 + 100 * overlaps) as keyof typeof green]
              : yellow[(500 + 100 * overlaps) as keyof typeof yellow]
          }
          width={`${(100 * duration) / totalDays}%`}
          height="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          m={0}
          mt={type === "work" ? "0" : "40px"}
          sx={{
            p: 1,
            borderRadius: 1,
            transition: "background-color 0.3s ease",
            position: "absolute",
            left: `${(100 * (startDate.getTime() - firstYearDate.getTime())) / (millisecondsInDay * totalDays)}%`,
            "&:hover": {
              backgroundColor: type === "work" ? green["900"] : yellow["900"],
            },
          }}
        >
          {duration > 100 && (
            <Typography variant="caption" color="black" noWrap sx={{ maxWidth: "100%" }}>
              {type === "work" ? experience.position : experience.subject}
            </Typography>
          )}
        </Box>
      </Tooltip>,
    );

    // Add labels for the timeline
    labels.push(
      <Box key={`label-${startDate.getFullYear()}-${startDate.getMonth()}`} m={1}>
        <Typography variant="caption" color="inherit">
          {startDate.getFullYear()}-{startDate.toLocaleString("default", { month: "short" })}
        </Typography>
      </Box>,
    );

    if (new Date(experience.toDate || new Date()) > currentTime) {
      currentTime = new Date(experience.toDate || new Date());
    }
  });
  return { timeline, labels };
};

interface MaijaExperienceTimelineProps {
  workExperiences: WorkExperience[];
  educations: Education[];
}

const MaijaExperienceTimeline: React.FC<MaijaExperienceTimelineProps> = ({ workExperiences, educations }) => {
  const { timeline } = generateTimelineBoxes(workExperiences, educations, t);

  // Calculate the start year (first experience year) and end year (current year)
  const getEarliestYear = () => {
    const workYears = workExperiences.map((exp) => new Date(exp.fromDate).getFullYear());
    const educationYears = educations.map((edu) => new Date(edu.fromDate).getFullYear());

    // Combine all years into one array
    const allYears = [...workYears, ...educationYears];

    // Get the earliest year
    return Math.min(...allYears);
  };

  const startYear = getEarliestYear();

  const endYear = new Date().getFullYear();

  const calculateMilestones = (startYear: number, endYear: number): number[] => {
    const totalYears = endYear - startYear;
    if (totalYears <= 20) {
      return Array.from({ length: totalYears + 1 }, (_, index) => startYear + index);
    } else {
      const interval = Math.ceil(totalYears / 20);
      return Array.from({ length: 21 }, (_, index) => startYear + index * interval).filter((year) => year <= endYear);
    }
  };

  // Determine milestone years
  const milestones = calculateMilestones(startYear, endYear);

  const startDate = new Date(getEarliestYear(), 0, 1);
  const endDate = new Date();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" padding="2%">
      <Box display="flex" position="relative" justifyContent="center" width="100%" mb={5} sx={{ height: 120 }}>
        {timeline}
      </Box>

      {/* Include the Timeline Component at the Bottom */}
      <TimelineAxis startDate={startDate} endDate={endDate} milestones={milestones} />
    </Box>
  );
};

interface TimelineProps {
  startDate: Date;
  endDate: Date;
  milestones: number[];
}

const TimelineAxis: React.FC<TimelineProps> = ({ startDate, endDate, milestones }) => {
  const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const calculateLeftPosition = (year: number) => {
    return ((year - startDate.getFullYear()) / (totalDays / 365.25)) * 100;
  };

  return (
    <Box sx={{ position: "relative", width: "100%", mt: 2, height: 30 }}>
      {/* Main timeline line */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 3,
          backgroundColor: "black",
          position: "relative",
          top: 15, // Adjusting to move the line lower within the component
        }}
      >
        {/* Arrow at the end */}
        <Box
          sx={{
            position: "absolute",
            right: "-4px",
            top: "-5px", // Moving the arrow head down to align with the timeline line
            borderLeft: "15px solid black",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
          }}
        />
      </Box>

      {/* Milestones */}
      {milestones.map((year) => (
        <Box key={year} sx={{ position: "absolute", left: `${calculateLeftPosition(year)}%`, top: "-10px" }}>
          <Typography
            sx={{
              position: "relative",
              mt: 0,
              top: -10,
              transform: "translateX(-50%)",
            }}
          >
            {year}
          </Typography>
          <Box sx={{ width: 2, height: 15, backgroundColor: "black", mt: 1, position: "relative", top: -15 }} />
        </Box>
      ))}
    </Box>
  );
};

export default MaijaExperienceTimeline;
