import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { addSkill, updateSkill } from "../../reducers/profilePageSlice";
import { submitSkill, updateSkill as apiUpdateSkill } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { Skill } from "../../types/Skill";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";

interface SkillModalProps {
  initialSkill: Skill | null;
  open: boolean;
  onClose: () => void;
}

const SkillModal: React.FC<SkillModalProps> = ({ open, onClose, initialSkill }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [skillName, setSkillName] = useState(initialSkill?.name ?? "");
  const [isEditMode, setIsEditMode] = useState(initialSkill !== null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddSkill = async (newSkill: { name: string }) => {
    try {
      const newSkillData = await submitSkill(newSkill);
      dispatch(addSkill(newSkillData));
      dispatch(showSnackbar({ message: t("profilePage.skill.addSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.addError"), severity: "error" }));
    }
  };

  const handleUpdateSkill = async (updatedSkill: { name: string; id: string }) => {
    try {
      const updatedSkillData = await apiUpdateSkill({ ...updatedSkill });
      dispatch(updateSkill(updatedSkillData));
      dispatch(showSnackbar({ message: t("profilePage.skill.updateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.updateError"), severity: "error" }));
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && skillName.trim()) {
      handleSave();
      event.preventDefault();
    } else if (event.key === "Escape") {
      if (isEditMode) {
        onClose();
      } else {
        clearData();
      }
    }
  };

  const clearData = () => {
    setSkillName("");
    onClose();
  };

  useEffect(() => {
    setSkillName(initialSkill?.name ?? "");
    setIsEditMode(initialSkill !== null);
  }, [initialSkill]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const handleSave = () => {
    if (initialSkill) {
      handleUpdateSkill({ name: skillName, id: initialSkill.id });
    } else {
      handleAddSkill({ name: skillName });
    }
    setSkillName("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={isEditMode ? onClose : clearData}>
      <DialogTitle>{isEditMode ? t("profilePage.skill.editTitle") : t("profilePage.skill.addTitle")}</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <TextField
          inputRef={inputRef}
          autoFocus
          margin="dense"
          label={t("profilePage.skill.nameLabel")}
          type="text"
          fullWidth
          value={skillName}
          onChange={(e) => setSkillName(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={isEditMode ? onClose : clearData} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!skillName.trim()}>
          {isEditMode ? t("profilePage.skill.saveChangesButton") : t("profilePage.skill.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkillModal;
