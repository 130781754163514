import React, { FC } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { RootState } from "./store";
import { useAppSelector } from "./hooks";

interface CustomThemeProviderProps {
  children: React.ReactNode;
}

// Function to calculate the brightness of a hex color
const getBrightness = (hex: string) => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (rgb) {
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
  return 0; // Default to dark text if unable to parse color
};

const CustomThemeProvider: FC<CustomThemeProviderProps> = ({ children }) => {
  const themeSettings = useAppSelector((state: RootState) => state.theme);

  // Determine contrast text color based on brightness
  const contrastTextPrimary =
    getBrightness(themeSettings.primaryColor) >= 128 ? "rgba(0, 0, 0, 0.87)" : "rgba(255, 255, 255, 0.87)";
  const contrastTextSecondary =
    getBrightness(themeSettings.secondaryColor) >= 128 ? "rgba(0, 0, 0, 0.87)" : "rgba(255, 255, 255, 0.87)";

  const theme = createTheme({
    palette: {
      primary: {
        main: themeSettings.primaryColor,
        contrastText: contrastTextPrimary,
      },
      secondary: {
        main: themeSettings.secondaryColor,
        contrastText: contrastTextSecondary,
      },
      background: {
        default: "#f2f4f6",
      },
      text: {
        primary: "#333333",
        secondary: "#636363",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: "2.5rem",
        fontWeight: 500,
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 500,
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 500,
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
    },
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
