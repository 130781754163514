import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { UpdateApplicantSupportInfo } from "./ProfilePageRepository";
import { updateSupportInfo as apiUpdateSupportInfo } from "./ProfilePageRepository";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import { AddressInfo, SupportInfoFieldType, SupportInfoFieldValueMap } from "./SupportInfoField";
import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { formatDayjsToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/StringUtils";
import { LocationFilterSelector } from "../application/methods/LocationFilterSelector";
import { getJobSearchPreferences } from "../application/ApplicationRepository";
import { validatePhoneNumber } from "../../utils/TextValidation";

interface SupportInfoModalProps<T extends SupportInfoFieldType> {
  type: SupportInfoFieldType;
  open: boolean;
  onClose: () => void;
  getUpdatedSupportInfo: (value: SupportInfoFieldValueMap[T]) => UpdateApplicantSupportInfo | undefined;
  initialValue: SupportInfoFieldValueMap[T];
}

const SupportInfoModal = <T extends SupportInfoFieldType>({
  open,
  onClose,
  getUpdatedSupportInfo,
  type,
  initialValue,
}: SupportInfoModalProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<SupportInfoFieldValueMap[T]>(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    try {
      const updatedSupportInfo = getUpdatedSupportInfo(value);
      if (!updatedSupportInfo) {
        return;
      }

      if (type === SupportInfoFieldType.PhoneNumber && !validatePhoneNumber(value as string)) {
        setValue(initialValue);
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.savePhoneNumberError"),
            severity: "error",
          }),
        );
        return;
      }

      const apiUpdatedSupportInfo = await apiUpdateSupportInfo(updatedSupportInfo);
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      dispatch(
        showSnackbar({
          message: t("profilePage.supportInfo.saveSuccess", {
            typeLabel: type,
          }),
          severity: "success",
        }),
      );
    } catch (error) {
      setValue(initialValue);
      dispatch(
        showSnackbar({
          message: t(`profilePage.supportInfo.saveError`),
          severity: "error",
        }),
      );
    } finally {
      onClose();
    }
  };

  const handleClose = () => {
    setValue(initialValue);
    onClose();
  };

  const typeLabel = () => {
    switch (type) {
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addressLabel");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.phoneNumberLabel");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.firstPreferredJobLabel");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.secondPreferredJobLabel");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.thirdPreferredJobLabel");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.desiredJobInFiveYearsLabel");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.workTimeLabel");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel");
      case SupportInfoFieldType.JobSearchLocation:
        return t("profilePage.supportInfo.jobSearchLocationLabel");
      case SupportInfoFieldType.Gender:
        return t("profilePage.supportInfo.genderLabel");
      default:
        return "";
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      let shouldSave = false;

      switch (type) {
        case SupportInfoFieldType.Address: {
          const addressValue = value as AddressInfo;
          shouldSave = Boolean(
            addressValue.streetName.trim() && addressValue.zipCode.trim() && addressValue.city.trim(),
          );
          break;
        }
        case SupportInfoFieldType.PhoneNumber:
        case SupportInfoFieldType.FirstPreferredJob:
        case SupportInfoFieldType.SecondPreferredJob:
        case SupportInfoFieldType.ThirdPreferredJob:
        case SupportInfoFieldType.DesiredJobInFiveYears:
        case SupportInfoFieldType.Gender: {
          const stringValue = value as string;
          shouldSave = stringValue.trim().length > 0;
          break;
        }
        case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        case SupportInfoFieldType.WorkTime:
        case SupportInfoFieldType.JobSearchLocation:
        default:
          shouldSave = true;
          break;
      }

      if (shouldSave) {
        handleSave();
        event.preventDefault();
      }
    }
  };

  const renderAddressFields = () => {
    const addressValue = value as AddressInfo;

    return (
      <>
        <TextField
          inputRef={inputRef}
          autoFocus
          margin="dense"
          label={capitalizeFirstLetter(t("profilePage.supportInfo.streetNameLabel"))}
          type="text"
          fullWidth
          value={addressValue.streetName}
          onChange={(e) => setValue({ ...addressValue, streetName: e.target.value } as SupportInfoFieldValueMap[T])}
          onKeyDown={handleKeyPress}
        />
        <TextField
          margin="dense"
          label={capitalizeFirstLetter(t("profilePage.supportInfo.zipCodeLabel"))}
          type="text"
          fullWidth
          value={addressValue?.zipCode}
          onChange={(e) => setValue({ ...addressValue, zipCode: e.target.value } as SupportInfoFieldValueMap[T])}
          onKeyDown={handleKeyPress}
        />
        <TextField
          margin="dense"
          label={capitalizeFirstLetter(t("profilePage.supportInfo.cityLabel"))}
          type="text"
          fullWidth
          value={addressValue?.city}
          onChange={(e) => setValue({ ...addressValue, city: e.target.value } as SupportInfoFieldValueMap[T])}
          onKeyDown={handleKeyPress}
        />
      </>
    );
  };

  const renderSingleField = (label: string) => (
    <TextField
      inputRef={inputRef}
      autoFocus
      margin="dense"
      label={capitalizeFirstLetter(label)}
      type="text"
      fullWidth
      value={value as string}
      onChange={(e) => setValue(e.target.value as SupportInfoFieldValueMap[T])}
      onKeyDown={handleKeyPress}
    />
  );

  const renderDatePicker = () => {
    const dateValue = value as string;

    return (
      <Box>
        <DatePicker
          sx={{ m: 2 }}
          inputRef={inputRef}
          autoFocus
          label={capitalizeFirstLetter(t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel"))}
          value={dayjs(dateValue)}
          maxDate={dayjs()}
          format="YYYY-MM-DD"
          onChange={(date) => setValue(formatDayjsToString(date) as SupportInfoFieldValueMap[T])}
        />
      </Box>
    );
  };

  const renderWorkTimeSelector = () => {
    const workTimeValue = value as number;

    const handleChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number as SupportInfoFieldValueMap[T]);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          pt: 12,
          ml: 5,
        }}
      >
        <Slider
          defaultValue={workTimeValue}
          getAriaValueText={(sliderValue) =>
            sliderValue === 100 ? t("profilePage.supportInfo.fullTimeLabel") : `${sliderValue}%`
          }
          step={5}
          marks
          min={0}
          max={100}
          valueLabelDisplay="auto"
          sx={{ width: "100%" }}
          value={workTimeValue}
          onChange={handleChange}
        />
        <Typography variant="subtitle1" sx={{ ml: 5, width: "160px" }}>
          {workTimeValue === 100 ? t("profilePage.supportInfo.fullTimeLabel") : `${workTimeValue}%`}
        </Typography>
      </Box>
    );
  };

  const renderLocationSelector = () => (
    <LocationFilterSelector
      onSelect={(selectedLocation) => {
        setValue(selectedLocation as SupportInfoFieldValueMap[T]);
      }}
    />
  );

  const renderGenderSelector = () => {
    const genderValue = value as string;

    return (
      <FormControl fullWidth margin="dense">
        <InputLabel>{capitalizeFirstLetter(t("profilePage.supportInfo.genderLabel"))}</InputLabel>
        <Select
          value={genderValue}
          onChange={(e) => setValue(e.target.value as SupportInfoFieldValueMap[T])}
          label={capitalizeFirstLetter(t("profilePage.supportInfo.genderLabel"))}
          fullWidth
        >
          <MenuItem value="MAN">{t("profilePage.supportInfo.genderMan")}</MenuItem>
          <MenuItem value="WOMAN">{t("profilePage.supportInfo.genderWoman")}</MenuItem>
          <MenuItem value="OTHER">{t("profilePage.supportInfo.genderOther")}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  useEffect(() => {
    if (type === SupportInfoFieldType.JobSearchLocation && open) {
      const fetchJobSearchPreferences = async () => {
        try {
          const data = await getJobSearchPreferences();
          const locations = data.jobSearchLocation;
          if (value !== locations && open) {
            setValue(locations as SupportInfoFieldValueMap[T]);
          }
        } catch (error) {
          console.error("Error fetching job search preferences:", error);
        }
      };

      fetchJobSearchPreferences();
    }
  }, [dispatch, open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("profilePage.supportInfo.editTitle", { typeLabel: typeLabel() })}</DialogTitle>
      <DialogContent sx={{ width: "600px" }}>
        {(() => {
          switch (type) {
            case SupportInfoFieldType.Address:
              return renderAddressFields();
            case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
              return renderDatePicker();
            case SupportInfoFieldType.WorkTime:
              return renderWorkTimeSelector();
            case SupportInfoFieldType.JobSearchLocation:
              return renderLocationSelector();
            case SupportInfoFieldType.Gender:
              return renderGenderSelector();
            case SupportInfoFieldType.PhoneNumber:
            case SupportInfoFieldType.FirstPreferredJob:
            case SupportInfoFieldType.SecondPreferredJob:
            case SupportInfoFieldType.ThirdPreferredJob:
            case SupportInfoFieldType.DesiredJobInFiveYears:
              return renderSingleField(typeLabel());
            default:
              return null;
          }
        })()}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("profilePage.supportInfo.saveChangesButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportInfoModal;
