import { Box, Typography, Button, CardProps, Card, MenuItem, Select, FormControl, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Application } from "../../../types/Application";
import { useAppDispatch } from "../../../hooks";
import { useMediaQuery, useTheme } from "@mui/material";
import { ChangeApplicationModal } from "../modals/ChangeApplicationModal";
import { updateApplicationParameters } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import {
  updateApplication,
  updateCoverLetterThumbnail,
  updateResumeThumbnail,
} from "../../../reducers/applicantDetilsPageSlice";
import ApplicationTone from "../../../types/ApplicationTone";
import ApplicationLanguage, { getLanguageByCode, SUPPORTED_LANGUAGES } from "../../../types/ApplicationLanguage";
import Flag from "react-world-flags";
import ApplicationTemplateStyle from "../../../types/ApplicationTemplateStyle";

export interface ChangeApplicationModuleProps extends CardProps {
  application: Application;
  maxWidth: number;
  setIsUpdating: (isUpdating: boolean) => void;
}

const tones = [
  ApplicationTone.Casual,
  ApplicationTone.Formal,
  ApplicationTone.Professional,
  ApplicationTone.Friendly,
  ApplicationTone.Humorous,
  ApplicationTone.Direct,
];

const templates = [ApplicationTemplateStyle.Modern, ApplicationTemplateStyle.Classic, ApplicationTemplateStyle.Sharp];

export const ChangeApplicationModule: React.FC<ChangeApplicationModuleProps> = ({
  application,
  maxWidth,
  setIsUpdating,
  ...cardProps
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [templateStyle, setTemplateStyle] = useState(application.templateStyle || ApplicationTemplateStyle.Modern);
  const [language, setLanguage] = useState<ApplicationLanguage>(getLanguageByCode(application.language));
  const [selectedTones, setSelectedTones] = useState<ApplicationTone[]>(application.tones || []);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleTemplateClick = (template: ApplicationTemplateStyle) => {
    setTemplateStyle(template);
  };

  const handleToneClick = (tone: ApplicationTone) => {
    if (selectedTones.includes(tone)) {
      setSelectedTones(selectedTones.filter((t) => t !== tone));
    } else if (selectedTones.length < 3) {
      setSelectedTones([...selectedTones, tone]);
    }
  };

  const handleSave = () => {
    if (application.tones !== selectedTones) {
      setConfirmModalOpen(true);
    } else {
      submitApplicationUpdate();
    }
  };

  const submitApplicationUpdate = () => {
    setConfirmModalOpen(false);
    setIsUpdating(true);
    updateApplicationParameters({
      applicationId: application.id,
      language: language.code,
      templateStyle: templateStyle,
      tones: selectedTones,
    })
      .then((data) => {
        if (data) {
          dispatch(updateApplication(data.application));
          dispatch(
            updateResumeThumbnail({
              applicationId: application.id,
              resumeThumbnail: data.resumeThumbnail,
            }),
          );
          dispatch(
            updateCoverLetterThumbnail({
              applicationId: application.id,
              coverLetterThumbnail: data.coverLetterThumbnail,
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("applicationDetails.page.updateError"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: maxWidth,
        mt: 6,
        pt: 2,
        pb: 4,
        px: 4,
        "& button": {
          px: isSmallScreen ? 5 : 10,
          py: isSmallScreen ? 1.5 : 3,
        },
        ...cardProps.sx,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t("applicationDetails.page.changeApplicationTitle")}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" gutterBottom>
          {t("applicationDetails.page.changeApplicationLanguage")}
        </Typography>
        <FormControl fullWidth>
          <Select
            labelId="language-label"
            value={language.code}
            onChange={(e) => setLanguage(getLanguageByCode(e.target.value))}
            renderValue={(selected) => {
              const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => lang.code === selected);
              return selectedLanguage ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Flag code={selectedLanguage.flag} style={{ marginRight: 8 }} width={20} />
                  {selectedLanguage.name}
                </Box>
              ) : (
                <em>{t("applicationDetails.page.changeApplicationOriginalLanguage")}</em>
              );
            }}
          >
            <MenuItem value="">
              <em>{t("applicationDetails.page.changeApplicationOriginalLanguage")}</em>
            </MenuItem>
            {SUPPORTED_LANGUAGES.map((language) => (
              <MenuItem key={language.code} value={language.code}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Flag code={language.flag} style={{ marginRight: 8 }} width={20} />
                  {language.name}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" gutterBottom>
          {t("applicationDetails.page.changeApplicationTemplate")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {templates.map((template) => (
            <Chip
              key={template}
              label={t("applicationDetails.page.templates." + template)}
              clickable
              color={templateStyle === template ? "secondary" : "default"}
              onClick={() => handleTemplateClick(template)}
            />
          ))}
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" gutterBottom>
          {t("applicationDetails.page.changeApplicationTone")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {tones.map((tone) => (
            <Chip
              key={tone}
              label={t("applicationDetails.page.tones." + tone)}
              clickable
              color={selectedTones.includes(tone) ? "secondary" : "default"}
              onClick={() => handleToneClick(tone)}
            />
          ))}
        </Box>
        <Typography variant="caption" color="textSecondary">
          {t("applicationDetails.page.toneLimit")}
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handleSave}>
        {t("applicationDetails.page.save")}
      </Button>

      <ChangeApplicationModal
        isOpen={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        handleConfirm={submitApplicationUpdate}
      />
    </Card>
  );
};
