import React, { useState } from "react";
import createApplicationWithDescription, {
  createApplicationWithAd,
  createApplicationWithLink,
} from "./ApplicationRepository";
import MaijaContainer from "../../components/MaijaContainer";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import generatingAnimationData from "../../assets/lottie/generating.json";

import MaijaLottieAnimation from "../../components/MaijaLottieAnimation";
import ApplicationMethod from "./methods/ApplicationMethod";
import { MaijaError } from "../../types/MaijaError";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { setApplicationDetails } from "../../reducers/applicantDetilsPageSlice";
import { useNavigate } from "react-router-dom";
import { navigateToApplicationDetails } from "../../Routes";
import { useTranslation } from "react-i18next";

const ApplicationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateManualApplication = (workplace: string, role: string, description: string) => {
    setIsGenerating(true);
    createApplicationWithDescription({
      workplace: workplace,
      role: role,
      description: description,
    })
      .then((data) => {
        if (data) {
          dispatch(
            setApplicationDetails({
              ...data,
              openedFromApplicationsList: false,
            }),
          );
          navigateToApplicationDetails(navigate, data.application.id);
        }
      })
      .catch(() =>
        dispatch(
          showSnackbar({
            message: t("application.page.errorGenerating"),
            severity: "error",
          }),
        ),
      )
      .finally(() => setIsGenerating(false));
  };

  const handleGenerateJobAdApplication = (link: string) => {
    setIsGenerating(true);
    createApplicationWithLink({
      link: link,
    })
      .then((data) => {
        if (data) {
          dispatch(
            setApplicationDetails({
              ...data,
              openedFromApplicationsList: false,
            }),
          );
          navigateToApplicationDetails(navigate, data.application.id);
        }
      })
      .catch((error: MaijaError) => {
        switch (error.type) {
          case "invalid-request":
            dispatch(
              showSnackbar({
                message: t("application.page.invalidRequest"),
                severity: "error",
              }),
            );
            break;
          default:
            dispatch(
              showSnackbar({
                message: t("application.page.errorGenerating"),
                severity: "error",
              }),
            );
            break;
        }
      })
      .finally(() => setIsGenerating(false));
  };

  const handleGenerateJobListAdApplication = (jobAdId: string) => {
    setIsGenerating(true);
    createApplicationWithAd({
      adId: jobAdId,
    })
      .then((data) => {
        if (data) {
          dispatch(
            setApplicationDetails({
              ...data,
              openedFromApplicationsList: false,
            }),
          );
          navigateToApplicationDetails(navigate, data.application.id);
        }
      })
      .catch((error: MaijaError) => {
        switch (error.type) {
          case "invalid-request":
            dispatch(
              showSnackbar({
                message: t("application.page.invalidRequest"),
                severity: "error",
              }),
            );
            break;
          default:
            dispatch(
              showSnackbar({
                message: t("application.page.errorGenerating"),
                severity: "error",
              }),
            );
            break;
        }
      })
      .finally(() => setIsGenerating(false));
  };

  return (
    <MaijaContainer>
      {isGenerating && <LoadingComponent />}
      {!isGenerating && (
        <ApplicationMethod
          handleGenerateManualApplication={handleGenerateManualApplication}
          handleGenerateJobAdApplication={handleGenerateJobAdApplication}
          handleGenerateJobListAdApplication={handleGenerateJobListAdApplication}
        />
      )}
    </MaijaContainer>
  );
};

const LoadingComponent: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
      }}
    >
      <MaijaLottieAnimation
        animationData={generatingAnimationData}
        size={isSmallScreen ? 300 : 500}
        show={true}
        loop={true}
      />
    </Box>
  );
};

export default ApplicationPage;
