import { Box, Typography, Button, Card, CardProps, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import {
  ApplicationDetails,
  setPendingApplication,
  updateApplication,
} from "../../../reducers/applicantDetilsPageSlice";
import { ApplicationActionType } from "../modals/ApplicationActionModal";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import {
  downloadApplication,
  emailApplication,
  markApplicationAsApplied,
} from "../../application/ApplicationRepository";
import { downloadBase64File } from "../../../utils/FileDownloadUtil";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { CloudDownload, Email, OpenInNew, CheckCircle } from "@mui/icons-material";
import React from "react";
import { AppDispatch } from "../../../store";

export interface SendApplicationModuleProps extends CardProps {
  maxWidth: number;
  applicationDetails: ApplicationDetails;
  handleOpenModal: (applicationActionType: ApplicationActionType) => void;
}

export const SendApplicationModule: React.FC<SendApplicationModuleProps> = ({
  maxWidth,
  applicationDetails,
  handleOpenModal,
  ...cardProps
}) => {
  const application = applicationDetails.application;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isEmailing, setIsEmailing] = React.useState(false);
  const [isApplying, setIsApplying] = React.useState(false);

  const handleDownloadApplication = () => {
    setIsDownloading(true);
    downloadApplication(application.id)
      .then((data) => {
        if (data) {
          downloadBase64File(data.resume, `Resume_${application.workplace}`);
          downloadBase64File(data.coverLetter, `Cover_letter_${application.workplace}`);
          dispatch(
            setPendingApplication({
              applicationId: application.id,
              pendingApplication: data.application,
            }),
          );
          handleOpenModal(ApplicationActionType.Download);
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("applicationDetails.page.downloadingError"),
            severity: "error",
          }),
        );
      })
      .finally(() => setIsDownloading(false));
  };

  const handleEmailApplication = () => {
    setIsEmailing(true);
    emailApplication(application.id)
      .then((data) => {
        handleOpenModal(ApplicationActionType.Email);
        dispatch(
          setPendingApplication({
            applicationId: application.id,
            pendingApplication: data,
          }),
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("applicationDetails.page.emailingError"),
            severity: "error",
          }),
        );
      })
      .finally(() => setIsEmailing(false));
  };

  const handleMarkAsAppliedWrapper = () => {
    handleMarkAsApplied(application.id, dispatch, t, setIsApplying);
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: maxWidth,
        my: 6,
        pt: 2,
        pb: 4,
        px: 4,
        "& button": {
          px: isSmallScreen ? 5 : 10,
          py: isSmallScreen ? 1.5 : 3,
        },
        ...cardProps.sx,
      }}
    >
      <Typography variant="h6" sx={{ mb: 4 }}>
        {t("applicationDetails.page.readyToApply")}
      </Typography>

      {/* Step 1: Download or Email Application */}
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        1. {t("applicationDetails.page.downloadOrMail")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "center" : "flex-start",
          mb: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadApplication}
          startIcon={isDownloading ? <CircularProgress size={24} color="inherit" /> : <CloudDownload />}
          disabled={isDownloading}
        >
          {t("applicationDetails.page.download")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: isSmallScreen ? 0 : 4, mt: isSmallScreen ? 2 : 0 }}
          onClick={handleEmailApplication}
          startIcon={isEmailing ? <CircularProgress size={24} color="inherit" /> : <Email />}
          disabled={isEmailing}
        >
          {t("applicationDetails.page.email")}
        </Button>
      </Box>

      {/* Step 2: Apply via Email, Website, or Ad URL */}
      <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
        2. {t("applicationDetails.page.applyInstructions")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "center" : "flex-start",
          mb: 4,
        }}
      >
        {applicationDetails.application.applyEmail && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mb: 2, mr: 2 }}
            startIcon={<Email />}
            onClick={() => window.open(`mailto:${applicationDetails.application.applyEmail}`, "_blank")}
          >
            {t("applicationDetails.page.applyEmail")}
          </Button>
        )}
        {applicationDetails.application.applyUrl && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mb: 2, mr: 2 }}
            startIcon={<OpenInNew />}
            onClick={() => window.open(applicationDetails.application.applyUrl, "_blank")}
          >
            {t("applicationDetails.page.applyWebsite")}
          </Button>
        )}
        {applicationDetails.application.adUrl && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mb: 2, mr: 2 }}
            startIcon={<OpenInNew />}
            onClick={() => window.open(applicationDetails.application.adUrl, "_blank")}
          >
            {t("applicationDetails.page.adUrl")}
          </Button>
        )}
        {!applicationDetails.application.applyEmail &&
          !applicationDetails.application.applyUrl &&
          !applicationDetails.application.adUrl && (
            <Typography variant="body1" color="textSecondary">
              {t("applicationDetails.page.noApplyInfo")}
            </Typography>
          )}
      </Box>

      {/* Step 3: Mark as Applied */}
      <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
        3. {t("applicationDetails.page.markAsAppliedInstructions")}
      </Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {applicationDetails.application.hasApplied ? (
          <Box sx={{ display: "flex", alignItems: "center", color: "green" }}>
            <CheckCircle />
            <Typography variant="body1" sx={{ ml: 1 }}>
              {t("applicationDetails.page.alreadyApplied")}
            </Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckCircle />}
            disabled={application.hasApplied || isApplying}
            onClick={handleMarkAsAppliedWrapper}
          >
            {t("applicationDetails.page.markAsApplied")}
          </Button>
        )}
      </Box>
    </Card>
  );
};

const handleMarkAsApplied = async (
  applicationId: string,
  dispatch: AppDispatch,
  t: (key: string) => string,
  setIsApplying: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsApplying(true);
  try {
    const response = await markApplicationAsApplied(applicationId, true);
    dispatch(updateApplication(response));
    dispatch(
      showSnackbar({
        message: t("applicationDetails.page.markAppliedSuccess"),
        severity: "success",
      }),
    );
  } catch (error) {
    console.error(error);
    dispatch(
      showSnackbar({
        message: t("applicationDetails.page.markAppliedError"),
        severity: "error",
      }),
    );
  } finally {
    setIsApplying(false);
  }
};
