import React, { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { addLanguage, updateLanguage } from "../../reducers/profilePageSlice";
import { submitLanguage, updateLanguage as apiUpdateLanguage } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { Language } from "../../types/Language";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";

interface LanguageModalProps {
  initialLanguage: Language | null;
  open: boolean;
  onClose: () => void;
}

const LanguageModal: React.FC<LanguageModalProps> = ({ open, onClose, initialLanguage }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [languageName, setLanguageName] = useState(initialLanguage?.name ?? "");
  const [isEditMode, setIsEditMode] = useState(initialLanguage !== null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddLanguage = async (newLanguage: { name: string }) => {
    try {
      const newLanguageData = await submitLanguage(newLanguage);
      dispatch(addLanguage(newLanguageData));
      dispatch(showSnackbar({ message: t("profilePage.language.addSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.language.addError"), severity: "error" }));
    }
  };

  const handleUpdateLanguage = async (updatedLanguage: { name: string; id: string }) => {
    try {
      const updatedLanguageData = await apiUpdateLanguage({ ...updatedLanguage });
      dispatch(updateLanguage(updatedLanguageData));
      dispatch(showSnackbar({ message: t("profilePage.language.updateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.language.updateError"), severity: "error" }));
    }
  };

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter" && languageName.trim()) {
      handleSave();
      event.preventDefault();
    } else if (event.key === "Escape") {
      if (isEditMode) {
        onClose();
      } else {
        clearData();
      }
    }
  };

  const clearData = () => {
    setLanguageName("");
    onClose();
  };

  useEffect(() => {
    setLanguageName(initialLanguage?.name ?? "");
    setIsEditMode(initialLanguage !== null);
  }, [initialLanguage]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const handleSave = () => {
    if (initialLanguage) {
      handleUpdateLanguage({ name: languageName, id: initialLanguage.id });
    } else {
      handleAddLanguage({ name: languageName });
    }
    setLanguageName("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={isEditMode ? onClose : clearData}>
      <DialogTitle>{isEditMode ? t("profilePage.language.editTitle") : t("profilePage.language.addTitle")}</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <TextField
          inputRef={inputRef}
          autoFocus
          margin="dense"
          label={t("profilePage.language.nameLabel")}
          type="text"
          fullWidth
          value={languageName}
          onChange={(e) => setLanguageName(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={isEditMode ? onClose : clearData} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!languageName.trim()}>
          {isEditMode ? t("profilePage.language.saveChangesButton") : t("profilePage.language.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageModal;
