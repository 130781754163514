import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { addWorkExperience, updateWorkExperience } from "../../reducers/profilePageSlice";
import { submitWorkExperience, updateWorkExperience as apiUpdateWorkExperience } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { WorkExperience } from "../../types/WorkExperience";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface WorkExperienceModalProps {
  initialWorkExperience: WorkExperience | null;
  open: boolean;
  onClose: () => void;
}

const WorkExperienceModal: React.FC<WorkExperienceModalProps> = ({ open, onClose, initialWorkExperience }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [workplace, setWorkplace] = useState(initialWorkExperience?.workplace ?? "");
  const [position, setPosition] = useState(initialWorkExperience?.position ?? "");
  const [fromDate, setFromDate] = useState(
    initialWorkExperience?.fromDate ? dayjs(initialWorkExperience.fromDate) : null,
  );
  const [toDate, setToDate] = useState(initialWorkExperience?.toDate ? dayjs(initialWorkExperience.toDate) : null);
  const [description, setDescription] = useState(initialWorkExperience?.description ?? "");
  const [isEditMode, setIsEditMode] = useState(initialWorkExperience !== null);
  const [isCurrentOccupation, setIsCurrentOccupation] = useState(initialWorkExperience?.toDate === null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setWorkplace(initialWorkExperience?.workplace ?? "");
    setPosition(initialWorkExperience?.position ?? "");
    setFromDate(initialWorkExperience?.fromDate ? dayjs(initialWorkExperience.fromDate) : null);
    setToDate(initialWorkExperience?.toDate ? dayjs(initialWorkExperience.toDate) : null);
    setDescription(initialWorkExperience?.description ?? "");
    setIsEditMode(initialWorkExperience !== null);
    setIsCurrentOccupation(initialWorkExperience?.toDate === null);
  }, [initialWorkExperience]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const handleSave = () => {
    if (isEditMode && initialWorkExperience) {
      const updatedWorkExperience: WorkExperience = {
        id: initialWorkExperience.id!,
        workplace,
        position,
        fromDate: fromDate!.toISOString(),
        toDate: isCurrentOccupation ? null : toDate?.toISOString() || null,
        description,
      };
      handleUpdateWorkExperience(updatedWorkExperience);
    } else {
      handleAddWorkExperience({
        workplace,
        position,
        fromDate: fromDate!.toISOString(),
        toDate: isCurrentOccupation ? null : toDate?.toISOString() || null,
        description,
      });
    }
    clearData();
  };

  const handleAddWorkExperience = async (newWorkExperience: Omit<WorkExperience, "id">) => {
    try {
      const newWorkExperienceData = await submitWorkExperience(newWorkExperience);
      dispatch(addWorkExperience(newWorkExperienceData));
      dispatch(showSnackbar({ message: t("profilePage.workExperience.addSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.workExperience.addError"), severity: "error" }));
    }
  };

  const handleUpdateWorkExperience = async (updatedWorkExperience: WorkExperience) => {
    try {
      const updatedWorkExperienceData = await apiUpdateWorkExperience(updatedWorkExperience);
      dispatch(updateWorkExperience(updatedWorkExperienceData));
      dispatch(showSnackbar({ message: t("profilePage.workExperience.updateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.workExperience.updateError"), severity: "error" }));
    }
  };

  const clearData = () => {
    setWorkplace("");
    setPosition("");
    setFromDate(null);
    setToDate(null);
    setDescription("");
    setIsCurrentOccupation(false);
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={isEditMode ? onClose : clearData} fullWidth={true}>
        <DialogTitle>
          {isEditMode ? t("profilePage.workExperience.editTitle") : t("profilePage.workExperience.addTitle")}
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={inputRef}
            autoFocus
            margin="dense"
            label={t("profilePage.workExperience.workplaceLabel")}
            type="text"
            fullWidth
            value={workplace}
            onChange={(e) => setWorkplace(e.target.value)}
            data-cy="workplace"
          />
          <TextField
            margin="dense"
            label={t("profilePage.workExperience.positionLabel")}
            type="text"
            fullWidth
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            data-cy="position"
          />

          <Box sx={{ my: 4, display: "flex" }}>
            <DatePicker
              sx={{ mr: 2, flexGrow: 1 }}
              label={t("profilePage.workExperience.fromDateLabel")}
              value={fromDate}
              onChange={(date) => setFromDate(date)}
              maxDate={dayjs()}
              format="YYYY-MM-DD"
              data-cy="from-date"
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />

            <DatePicker
              sx={{ flexGrow: 1 }}
              label={t("profilePage.workExperience.toDateLabel")}
              value={toDate}
              minDate={fromDate}
              format="YYYY-MM-DD"
              onChange={(date) => setToDate(date)}
              disabled={isCurrentOccupation}
              data-cy="to-date"
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={isCurrentOccupation}
                onChange={(e) => setIsCurrentOccupation(e.target.checked)}
                data-cy="current-occupation"
              />
            }
            label={t("profilePage.workExperience.currentOccupationLabel")}
          />

          <TextField
            margin="dense"
            label={t("profilePage.workExperience.descriptionLabel")}
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={isEditMode ? onClose : clearData} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={
              !workplace.trim() ||
              !position.trim() ||
              !fromDate ||
              !dayjs(fromDate).isValid() ||
              (!isCurrentOccupation && (!toDate || !dayjs(toDate).isValid()))
            }
          >
            {isEditMode ? t("profilePage.workExperience.saveChangesButton") : t("profilePage.workExperience.addButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default WorkExperienceModal;
