import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, Typography } from "@mui/material";
import { RootState } from "../../store";
import { postSelfAssessmentAnswer as apiPostSelfAssessmentAnswer } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setSelfAssessmentQuestionAnswer } from "../../reducers/profilePageSlice";
import { SelfAssessmentQuestion } from "../../types/SelfAssessment";
import { useTranslation } from "react-i18next";

const SelfAssessmentComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const questions = useSelector((state: RootState) => state.profilePage.selfAssessmentQuestions);

  const onAnswer = async (id: string, answer: string) => {
    try {
      dispatch(
        setSelfAssessmentQuestionAnswer({
          questionId: id,
          answer: answer,
        }),
      );
      await apiPostSelfAssessmentAnswer({
        questionId: id,
        answer: answer,
      });
      dispatch(showSnackbar({ message: t("profilePage.selfAssessment.answerSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.selfAssessment.answerError"), severity: "error" }));
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {questions && questions.length > 0 ? <QuestionsList questions={questions} onAnswer={onAnswer} /> : <EmptyState />}
    </Box>
  );
};

interface QuestionsListProps {
  questions: SelfAssessmentQuestion[];
  onAnswer: (id: string, answer: string) => void;
}

const QuestionsList: React.FC<QuestionsListProps> = ({ questions, onAnswer }) => {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  const handleRadioChange = (questionId: string, answer: string) => {
    onAnswer(questionId, answer);
  };

  const handleNext = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("profilePage.selfAssessment.title")}
      </Typography>

      {/* Question Counter */}
      <Typography variant="body2" sx={{ mb: 2 }}>
        {t("profilePage.selfAssessment.questionCounter")} {currentQuestionIndex + 1}{" "}
        {t("profilePage.selfAssessment.of")} {totalQuestions}
      </Typography>

      {/* Progress Bar */}
      <Box sx={{ width: "100%", mb: 3 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>

      {/* Current Question */}
      <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
        {currentQuestion.question}
      </Typography>

      {/* Answer Options */}
      <RadioGroup
        name={`question-${currentQuestion.id}`}
        value={currentQuestion.answer || ""}
        onChange={(event) => handleRadioChange(currentQuestion.id, (event.target as HTMLInputElement).value)}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", mb: 3 }}
      >
        {/* The box forces the buttons to line up */}
        <Box>
          {currentQuestion?.allowedAnswers?.map((answer, index) => (
            <FormControlLabel
              key={index}
              value={answer}
              control={<Radio size="small" />}
              label={answer}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: 1,
              }}
            />
          ))}
        </Box>
      </RadioGroup>

      {/* Navigation Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mt: 2 }}>
        <Button variant="contained" onClick={handleBack} disabled={currentQuestionIndex === 0} sx={{ width: "45%" }}>
          {t("common.back")}
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={currentQuestionIndex === totalQuestions - 1}
          sx={{ width: "45%" }}
        >
          {t("common.next")}
        </Button>
      </Box>
    </Box>
  );
};

const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.selfAssessment.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.selfAssessment.emptyStateMessage")}
      </Typography>
    </Box>
  );
};

export default SelfAssessmentComponent;
